import React from "react"
import Box from "@material-ui/core/Box"
import NoSsr from "@material-ui/core/NoSsr"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Seo from "../components/seo"
import Cloud from "../components/cloud"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
  },
}))

const ContactPage = ({ data }) => {
  const classes = useStyles()
  const { datoCmsContactPage } = data
  const sections = (datoCmsContactPage && datoCmsContactPage.section) || []

  return (
    <Layout bgColor="#ffdd00">
      <Seo title="Kontakt" />
      <Box className={classes.root}>
        <Box position="relative">
          <Cloud position="left" />
          <Container maxWidth="md">
            <NoSsr>
              <Box mb={10}>
                <iframe
                  title="Mapa dojazdu"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.0610953993387!2d21.00741231626887!3d52.078617679733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47192e3ccb3c8865%3A0xfa5948decc174bbe!2sKakadu%20Sala%20Zabaw!5e0!3m2!1spl!2spl!4v1625678033907!5m2!1spl!2spl"
                  width="100%"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </Box>
            </NoSsr>
          </Container>
        </Box>
        <Box position="relative">
          <Cloud position="right" />
          <Container maxWidth="md">
            {sections.map(section => (
              <Box key={section.id}>
                <Typography gutterBottom variant="h1">
                  {section.title}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: section.description }}
                />
              </Box>
            ))}
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    datoCmsContactPage {
      section {
        id
        title
        description
      }
    }
  }
`

export default ContactPage
